:root {
  --blue: #3059a5;
  --blue-ligth: #7fbce9;
  --blue-ligth-o: rgba(127, 188, 233, 0.67);
  --yellow: #e4bf4c;
  --gold: #a9924c;
  --purple: #5b49cf;
  --mint: #23d3a4;
  --white: #fff;
  --white-o: rgba(218, 218, 218, 0.27);
  --grey: #5f6367;
  --red: tomato;
  --blue-green: #357289;
  --orange: #e89900;
  --icon-gradient: linear-gradient(
    207.67deg,
    var(--mint) 3.43%,
    var(--purple) 104.7%
  );
  --btn-gradient: linear-gradient(275.76deg, #e4bf4c 44.33%, #a9924c 98.56%);
  --btn-gradient-hover: linear-gradient(
    275.76deg,
    #a9924c 44.33%,
    #e4bf4c 98.56%
  );
}

@font-face {
  font-family: "LufgaRegular";
  src: url(./assets/LufgaRegular.ttf);
}

#root {
  max-width: 100vw;
}

* {
  box-sizing: border-box;
}

main {
  min-height: calc(100vh - 64px);
}

h1,
h2,
h3 {
  font-weight: bold;
  color: var(--blue);
}

p {
  font-size: 18px;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h3 .light {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: "LufgaRegular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  background: #ffffff;
  border: 1px solid #858c94;
  box-sizing: border-box;
  border-radius: 8px;
}

button {
  border: none;
}

a {
  text-decoration: none;
  color: black;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.btn {
  box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  text-align: center;
  text-decoration: none;
  padding: 16px 18px;
  font-weight: bold;
  background: var(--btn-gradient);
  color: var(--white);
}

.btn:hover {
  background: var(--btn-gradient-hover);
}

.btn.btn-text {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  padding: 8px 9px;
}

.btn.btn-icon {
  background-color: transparent;
  color: var(--icon-gradient);
  box-shadow: 0px 0px 0px transparent;
}

.btn.btn-text:hover,
.btn.btn-icon:hover {
  color: var(--blue);
}

.btn.btn-secondary {
  border: 1px solid var(--grey);
  color: var(--grey);
  background: transparent;
}

.btn-facebook {
  color: #2e4ebe !important;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
}

.btn-apple {
  background: #c1b7b7 !important;
  color: var(--grey);
  border-radius: 50%;
  border: none;
}

.btn-google {
  background: #a92a2a !important;
  color: var(--grey);
  border-radius: 50%;
  border: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold !important;
}

.text-light {
  font-weight: 400 !important;
}

.text-blue-green {
  color: var(--blue-green);
}

.text-white {
  color: var(--white);
}

.text-blue {
  color: var(--blue);
}

.text-red {
  color: var(--red) !important;
}

.text-orange {
  color: var(--orange) !important;
}

.text-yellow {
  color: var(--yellow) !important;
}

.text-muted {
  color: var(--grey) !important;
}

.text-yellow {
  color: var(--yellow) !important;
}

.text-big {
  font-size: 48px;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-white-o {
  background: var(--white-o) !important;
}

.bg-red {
  background-color: var(--red) !important;
}

.bg-orange {
  background-color: var(--orange) !important;
}

.bg-grey {
  background-color: var(--grey) !important;
}

.bg-blue {
  background-color: var(--blue) !important;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-24 {
  margin-right: 24px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-24 {
  margin-left: 24px;
}

.mb-0 {
  margin-bottom: 0;
}
/* MUI Icons - Material Icons colores */
.MuiSvgIcon-colorSecondary {
  color: var(--blue) !important;
}

.MuiSvgIcon-colorPrimary {
  color: var(--white) !important;
}

.MuiCircularProgress-colorPrimary {
  color: var(--orange) !important;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start !important;
}

/* Divider html */

.horizontal-separator {
  border: 2px solid #357289;
  width: 10%;
  margin: 32px auto;
}

/* Scrollbar style */

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/* Form styles */

.form-group {
  margin: 0 8px 16px;
  display: flex;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin: 0 4px;
}

/* Animacion al hacer Scroll */

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 0.75s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

/* ------------------------------------ */

/* Animations */

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 360px) {
}
