/* Loader Classes */

.loader {
  width: 100%;
  height: 100vh;
  background: url(../../assets/background-oha.png) no-repeat center center;
  background-size: cover;
}

.loader > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: pulse ease-in 3s infinite;
}

.loader > div > * {
  margin: 16px 0;
}
